import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import ModalVideo from '../../../components/ModalVideo'
import Link from '../../../components/Link'
import BookDemoSection from '../../../components/BookDemoSection'
import {bookADemoLinkSwitch} from '../../../components/lib/bookADemoLinkSwitch'

import '../../platform.scss'

import home2022_1 from '../../../images/features2023/leave-and-payroll/Feature_Images/23-135_MyHR Payroll.png'
import home2022_2 from '../../../images/features2023/leave-and-payroll/Feature_Images/MyHR-PlatformPayroll Integrations AU@2x.png'
import home2022_3 from '../../../images/features2023/leave-and-payroll/Feature_Images/MyHR-PlatformLeave Management Software@2x.png'
import home2022_4 from '../../../images/features2023/leave-and-payroll/Feature_Images/MyHR-PlatformShared Calendar@2x.png'
import home2022_5 from '../../../images/features2023/leave-and-payroll/Feature_Images/MyHR-PlatformEmployee Self Service AU@2x.png'

import otherFeatures1 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-HR Compliance and HR Advisory@2x.png'
import otherFeatures2 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Hiring and Onboarding@2x.png'
import otherFeatures3 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Admin and Reporting@2x.png'
import otherFeatures4 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Leave and Payroll@2x.png'
import otherFeatures5 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Performance Reviews@2x.png'

import resource1 from '../../../images/features2023/resource-thumbnails/MyHR_Leave-and-Payroll_Resources_Managing Sick Leave@2x.png'
import resource2 from '../../../images/features2023/resource-thumbnails/MyHR_Leave-and-Payroll_Resources_Payroll and Leave White Paper@2x.png'
import resource3 from '../../../images/features2023/resource-thumbnails/MyHR_Leave-and-Payroll_Resources_5 Reasons Annual Leave@2x.png'

import bannerIcon1 from '../../../images/features2023/leave-and-payroll/Top_Banner_Icons/23-135_MyHR_Payroll-platform-page_Fast-and-easy-solution.png'
import bannerIcon2 from '../../../images/features2023/leave-and-payroll/Top_Banner_Icons/23-135_MyHR_Payroll-platform-page_Automated.png'
import bannerIcon3 from '../../../images/features2023/leave-and-payroll/Top_Banner_Icons/23-135_MyHR_Payroll-platform-page_Connect-the-dots.png'

import customerStoryLogoAU from '../../../images/features/customer_story_logos/HSE2.png'
import imageVidAU from '../../../images/features/customer_thumbnails/MyHR_Features_HSE_thumb.jpg'
const videoAU = {
  url: 'https://www.youtube.com/embed/kYU7cOWOGyM',
  channel: 'youtube',
}

const PAGE_CONFIG = {
  locale: "en-AU",
  meta: {
    title: "Our Platform | Leave & Payroll",
    description: "Smart, efficient HR leave management system that integrates with online employee payroll software. The best choice for small & medium sized businesses."
  },
  tourLink: "https://app.storylane.io/share/aqzawiaipcxm",
}

export default () => {
  const demoLink = bookADemoLinkSwitch(PAGE_CONFIG.locale);
  
  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  const clickCard = (event) => {
    event.preventDefault();
    var el = event.currentTarget;
    window.location.href = el.getAttribute("data-href")
  }

  return (
    <Layout
      className="PlatformPage"
      title={PAGE_CONFIG.meta.title}
      description={PAGE_CONFIG.meta.description}
      locale={PAGE_CONFIG.locale}
    >
      <Hero variant="leave-and-payroll">
        <h1>Simple payroll and leave management software</h1>
        <p>
          Automate payroll and leave processes, so you can be sure your people are paid correctly and on time, every pay run.
        </p>
        <Button
          color="cyan"
          href={demoLink}
          className="mr-3"
        >
          Book a demo
        </Button>

        <Button
          color="outline-cyan"
          href={PAGE_CONFIG.tourLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
          Take the tour
        </Button>
      </Hero>

      <Section className="BannerIconSection">
        <Container>
          <Row className="">
            <Col xs={4} md={4} lg={4} className="bannerIcon new short">
              <img src={bannerIcon1} />
              <p>Single touch payroll for Australian businesses</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon2} />
              <p>Automate the flow of data between employees, payroll, and management</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new short">
              <img src={bannerIcon3} />
              <p>Integrate with best-in-class payroll providers</p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="Content greyBack">
        <Container>

          <Row className="mt-5 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>MYHR PAYROLL</h4>
              <h3>An end-to-end payroll solution</h3>
              <p>
                MyHR’s cloud-based payroll platform automates the flow of data from the employee to payroll and back to the business. 
                Save time and admin with Single Touch Payroll reporting, ensure Fair Work compliance, automate payroll processing and 
                sync with major accounting software, whilst speeding up employee onboarding and streamlining leave management with 
                employee self-service.
              </p>
              <ul>
                <li>Single Touch Payroll software</li>
                <li>Automated payroll and pay run processing</li>
                <li>Pre & post-tax deductions</li>
                <li>Employee timesheets and payslips</li>
                <li>Leave and payroll syncing</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_1} className="make-smallerish" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_2} className="" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>PAYROLL INTEGRATIONS</h4>
              <h3>Integrate with leading payroll providers</h3>
              <p>
                MyHR fully integrates with payroll software providers for a seamless payroll experience. Receive timely, accurate 
                payments, stress-free legal compliance, and connect the dots between payroll, people, and processes. Reduce administration 
                and streamline employee onboarding, leave management, and payroll processing.
              </p>
              <ul>
                <li>Simple leave and payroll syncing</li>
                <li>No double-handling</li>
                <li>Get payroll right and on time, every time</li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>LEAVE MANAGEMENT</h4>
              <h3>Easy employee leave management</h3>
              <p>
                Schedule and manage employee time-off simply and accurately, with cloud-based leave management software. Track approvals, 
                review leave in a shared calendar, and fully integrate with your chosen payroll service. 
              </p>
              <ul>
                <li>Accurate leave tracking and approval</li>
                <li>Shared leave calendars</li>
                <li>Smart notes</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_3} className="" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_4} className="" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>SHARED CALENDAR</h4>
              <h3>Schedule and keep track of key events</h3>
              <p>
                Keep track of leave requests, scheduled leave, holidays, and other important events in our powerful digital calendar. Receive 
                notifications and custom reminders so nothing is overlooked.
              </p>
              <ul>
                <li>Shared access & visibility</li>
                <li>Customisable reminders</li>
                <li>Easy organisation </li>
                <li>Leave Management</li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>EMPLOYEE SELF-SERVICE</h4>
              <h3>Keep employees empowered and engaged</h3>
              <p>
                Secure employee self-service all in one place. Update personal details, apply for leave, participate in performance reviews, 
                sign employment agreements, and view important company documents.
              </p>
              <ul>
                <li>Intuitive dashboard displays</li>
                <li>Easy data entry</li>
                <li>Leave application</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_5} className="" />
            </Col>
          </Row>

        </Container>
      </Section>

      <Section className="BannerSection py-5 justify-content-center d-flex flex-column align-items-center">
        <Container>
          <Row className="my-5">
            <Col
              md={{ size: 12, offset: 0 }}
              lg={{ size: 6, offset: 0 }}
            >
              <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                <div className="customerStoriesDiv">
                  <div className="d-block d-md-none my-3">
                    <ModalVideo {...videoAU}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={imageVidAU} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </div>
                  <h3 className="my-4 pr-0 pr-md-6 quoteText">
                    “The hands-on HR support has been incredibly beneficial. They’ve helped us through a lot of sticky situations.”
                  </h3>
                  <div className="customerStoryBy">
                    <p>
                      <span>Amanda Tait, HR Coordinator, HSE</span>
                    </p>
                    <img
                      src={customerStoryLogoAU}
                      className="customerStoryLogo"
                    alt="Logo" />
                  </div>
                  <Link
                    type="button"
                    color="radical-red"
                    href="/customer-stories"
                    className="customerStoriesBtn"
                    localePrefix={true}
                  >
                    More customer stories
                  </Link>
                  <p>&nbsp;</p>
                </div>
              </div>
            </Col>
            <Col
              className="d-none d-md-block"
              md={{ size: 12 }}
              lg={{ size: 6 }}
            >
              <ModalVideo {...videoAU}>
                {({ toggle }) => (
                  <div className="UntangleHrSection__video" onClick={toggle}>
                    <img src={imageVidAU} alt={'Video Thumbnail'} />
                    <div className="UntangleHrSection__video-play-icon">
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="icon play-icon"
                      />
                    </div>
                  </div>
                )}
              </ModalVideo>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="OtherFeaturesSection Content">
        <h2 className="text-center">Check out our other features</h2>
        <Container>
          <Row className="mt-6">
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/hr-compliance-and-advisory">
                <img src={otherFeatures1} />
                <h3>HR Compliance & <span>HR Advisory</span></h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/hr-compliance-and-advisory"
                  className="mr-3"
                >
                  Compliance features
                </Button>
                <hr />
                <ul>
                  <li>Cloud-based contracts</li>
                  <li>HR documents on demand</li>
                  <li>HR advice & platform support</li>
                  <li>100% paperless</li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/hiring-and-onboarding">
                <img src={otherFeatures2} />
                <h3>Hiring & Onboarding</h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/hiring-and-onboarding"
                  className="mr-3"
                >
                  Onboarding features
                </Button>
                <hr />
                <ul>
                  <li>E-sign contracts</li>
                  <li>Paperless onboarding</li>
                  <li>Recruitment support</li>
                  <li>Employment agreements</li>
                </ul>
              </div>
            </Col>
                        <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/performance-review-development">
                <img src={otherFeatures5} />
                <h3>Performance & Development</h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/performance-review-development"
                  className="mr-3"
                >
                  Performance features
                </Button>
                <hr />
                <ul>
                  <li>Paperless performance reviews</li>
                  <li>Automated smart notes</li>
                  <li>Employee development</li>
                  <li>Integrated advisory & support</li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/admin-and-reporting">
                <img src={otherFeatures3} />
                <h3>Admin & Reporting</h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/admin-and-reporting"
                  className="mr-3"
                >
                  Admin features
                </Button>
                <hr />
                <ul>
                  <li>2-factor authentication</li>
                  <li>Employee self-service</li>
                  <li>Industry benchmarks</li>
                  <li>Custom reminders</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>     

      <Section className="ResourceSection">
        <h3 className="text-center">HR Resource Bundle</h3>
        <Container>
          <Row className="mt-6">
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100" onClick={clickCard} data-href="https://blog.myhr.works/en-au/managing-sick-leave-and-absenteeism">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faBookOpen} /> Blog</p>
                    <h3>Managing sick leave: an employer’s guide to absenteeism</h3>
                  </div>
                  <img src={resource1} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>Details and answers to common questions about sick leave and managing absences.</p>
                  <a className="sectionLinkArrow" href="https://blog.myhr.works/en-au/managing-sick-leave-and-absenteeism">Read the blog post&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100"  onClick={clickCard} data-href="https://info.myhr.works/en-au/payroll-and-leave-management-wp">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faBook} /> White Paper</p>
                    <h3>Payroll & Leave Management</h3>
                  </div>
                  <img src={resource2} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>Why it's essential, and ways your business can make both processes easier and more accurate.</p>
                  <a className="sectionLinkArrow" href="https://info.myhr.works/en-au/payroll-and-leave-management-wp">Read the white paper&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100" onClick={clickCard} data-href="https://blog.myhr.works/en-au/5-reasons-why-leave-management-is-important">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faBookOpen} /> Blog</p>
                    <h3>5 reasons why leave management is important</h3>
                  </div>
                  <img src={resource3} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>There are some really good reasons why you should diligently manage, track, and pay leave.</p>
                  <a className="sectionLinkArrow" href="https://blog.myhr.works/en-au/5-reasons-why-leave-management-is-important">Read the blog post&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <BookDemoSection />

      <Section className="FaqSectionOld">
        <Container>
          <h2 className="mb-5">Leave and payroll FAQs</h2>
          <ul>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>What are key features of HR payroll software?</h3>
              <p>
                Having a payroll system that pays employees correctly and on time, in a way that meets legal obligations 
                under the laws covering payroll processes is the most important feature of any HR payroll software. 
                The system also has to ensure any tax or other deductions are made, and a record of the number of hours 
                an employee worked and the pay for those hours, as well as holidays and leave balances are kept.
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>What are the features of a leave management system?</h3>
              <p>
                Leave management systems provide a process for recording and tracking employee time-off, from requesting and 
                approving leave to maintaining accurate leave balances and matching leave with payroll. A good modern leave 
                management system should be digital and cloud-based with minimal manual processing and easy integration, so 
                employees and managers can check and control leave and requests for time-off, and ensure the company's leave 
                processes are legally compliant.
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>What are the benefits of using an integrated leave management and payroll system?</h3>
              <p>
                An integrated payroll and leave management system eliminates manual processing of leave and payroll data, 
                reduces paperwork, increases transparency, and, in most cases, makes both processes quicker, more accurate, 
                and more cost-effective.
                <br /><br />
                <a href="https://blog.myhr.works/en-au/advantages-of-integrated-leave-management-payroll-systems">Read more</a>
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>How many holidays does an employee have in Australia?</h3>
              <p>
                Under the National Employment Standards, Australian employees are entitled to a variety of paid and unpaid leave, 
                including annual leave, public holidays, parental leave, personal/carer's leave, compassionate leave, long service 
                leave, and community service leave. The exact amount of leave and holidays a worker can take can differ depending 
                on the state or territory they work in.
                <br /><br />
                <a href="https://blog.myhr.works/en-au/blog-public-holidays">Read more about public holidays in Australia</a>
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>How can I prevent my employees from abusing sick leave?</h3>
              <p>
                There are several ways you can prevent employees from abusing sick leave. You can legally ask for proof of sickness 
                or injury once an employee has been off work for a day or less, but you must inform them as soon as possible. If a 
                person has been using a lot of sick (or other) leave, honest communication could help understand if there are wider 
                issues involved. A pattern of abuse may require further action, but you should get expert advice to ensure you 
                follow proper process.
                <br /><br />
                <a href="https://blog.myhr.works/managing-sick-leave-an-employers-guide-to-absenteeism">Read more about sick leave entitelments</a>
              </p>
            </li>
          </ul>
        </Container>
      </Section>

    </Layout>
  )
}
